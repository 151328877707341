<template>
  <div class="common-wrapper" style="padding-top: 0;">
    <top-tabs @tab="handleTabChange" @check="handleTreeCheck" @load="initSuccess"/>
    <div class="content_wrap">
      <!-- <a-spin v-if="loading" /> -->
      <template v-for="item in tabLists" :key="item.id">
        <div v-show="item.id == currentId" style="width: 100%; height: 100%">
          <iframe  :ref="(ref)=>iframeRefs[item.id] = ref" style="width: 100%; height: 100%;background: #fff;" frameborder="0"></iframe>
        </div>   
      </template>
      <div class="noPage" v-if="!tabLists || (tabLists&&tabLists.length == 0)">
        <img src="@/assets/not-finish.png" alt="">
        <p>当前项目，没有相关视图页面，请先进行视图配置...</p>
      </div>
    </div>
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'ProjectMonitor'
// })
import { ref, onMounted, watch } from 'vue'
import TopTabs from './components/topTabs.vue'
import { useRoute } from 'vue-router';
import { useUserStore } from "@/store/modules/user";

const userStore = useUserStore();
const route = useRoute()
watch(() => route.path, (val) => {
  if (val === '/centerMonitor/projectMonitor') {
    loading.value = true
  }
})

const currentId = ref(null);
const tabLists = ref([]);
const noPage = ref(true);
const initSuccess = (val)=>{
  tabLists.value = val;
  if(val&&val.length>0){
    noPage.value = false;
  }
}


const iframeRefs = ref({});
const loading = ref(false)
const projectIdKey = ref()
const handleTabChange = (tabUrl, projectIds, id) => {
  currentId.value = id;
  projectIdKey.value = projectIds
  setTimeout(() => {
    loading.value = true
    iframeRefs.value[id].onload = function () {
      loading.value = false
      iframeRefs.value[id].contentWindow.postMessage(JSON.stringify({
        isParentData: true,
        info: {
          projectId: projectIds,
          token: userStore.token
        }
      }), '*')
    }
    iframeRefs.value[id].src = tabUrl;
  }, 0)

}

const currentProjectIds = ref(null)
const handleTreeCheck = (info) => {
  if (Array.isArray(info) && info.length > 0) {
    currentProjectIds.value = info
  } else {
    currentProjectIds.value = null
  }
}

onMounted(() => {

})


</script>
<style lang='less' scoped>
.content_wrap {
  height: calc(100vh - 184px);
}
.noPage{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  p{
    margin-top: 20px;
    font-size: 16px;
    color: #999;
  }
}
</style>

