<template>
  <a-tabs class="myTabs" v-model:activeKey="activeKey" @change="tabChange">
    <a-tab-pane
      v-for="item in tabsList"
      :key="item.viewUrl"
      :tab="item.name"
    ></a-tab-pane>
    <template #leftExtra>
      <project-tree @select="handleTreeSelect"></project-tree>
    </template>
  </a-tabs>
</template>
<script setup>
import { ref, defineEmits } from "vue";
import { apiViewTab } from "@/api/centerMonitor/projectMonitor.js";
import projectTree from "@/components/business/projectTree/index.vue";

const tabsList = ref([]);
const activeKey = ref(null);
const projectIds = ref(null);

const emits = defineEmits(["check", "tab", "load"]);
const initCategory = async () => {
  if (!projectIds.value) return;
  let res = await apiViewTab({ bizProjectIds: projectIds.value.join(",") });
  tabsList.value = res.result.filter((item) => item.viewType == 1);
  if (tabsList.value.length > 0) {
    let code = tabsList.value[0] && tabsList.value[0]["viewUrl"];
    activeKey.value = code;
    emits(
      "tab",
      code,
      projectIds.value,
      tabsList.value[0] && tabsList.value[0]["id"]
    );   
  }
  emits("load", tabsList.value);
};

const handleTreeSelect = (arrayIds) => {
  projectIds.value = arrayIds;
  initCategory();
  emits("check", arrayIds);
};

const tabChange = (val) => {
  let findOne = tabsList.value.find((item) => item.viewUrl == val);
  emits("tab", val, projectIds.value, findOne && findOne.id);
};

</script>
<style lang="less" scoped>
.dropdownStyle {
  z-index: 10;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  span {
    margin-right: 5px;
  }

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: #ebebeb;
    left: 0;
    bottom: -10px;
  }
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #d8d8d8;
  background: #fff;
}

.myTabs {
  :deep(.ant-tabs-nav) {
    min-height: 46px !important;
  }
}
</style>
